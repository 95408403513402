import React, { useContext } from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import PageContext from "@PageContext";
import Elements from "./elements";
import { Element } from "react-scroll";
import WebProfileContext from "@WebProfileContext";

const ReactPwaAlert = React.lazy(() =>
	import(
		/* webpackChunkName: "reactPwaAlert" */ "@micado-digital/react-pwa-alert/ReactPwaAlert"
	)
);

const useStyles = makeStyles(theme => ({
	content: {
		background: "linear-gradient(180deg, rgba(32, 48, 61, 0) 0%, rgba(32, 48, 61, 0.7) 100%)",
		padding: theme?.spacing(7.5, 0),
		[theme.breakpoints.up("sm")]: {
			padding: theme?.spacing(12.5, 0)
		},
		backgroundImage: "url('/img/bg.webp')",
		backgroundSize: "contain",
		backgroundRepeat: "repeat",
		position: "relative",
		"&:before": {
			background:
				"linear-gradient(180deg, rgba(32, 48, 61, 0) 0%, rgba(32, 48, 61, 0.7) 100%)",
			content: "''",
			height: "100%",
			left: 0,
			mixBlendMode: "multiply",
			position: "absolute",
			top: 0,
			width: "100%"
		},
		"& > *": {
			position: "relative"
		},
		"&.no-animation": {
			background: "none",
			"&:before": {
				display: "none"
			}
		}
	}
}));

const Standard = ({ variant }) => {
	const css = useStyles();
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const [profileData] = useContext(WebProfileContext);
	const { lang, elements } = pageData;

	return (
		<>
			<Element name="scroll-start"></Element>
			<div className={clsx(css.content, "mco-content", variant)}>
				<Elements items={elements} lang={lang} layoutVariant={variant}></Elements>
				{profileData?.pwaShowInstallHint && (
					<ReactPwaAlert appIcon={REACT_APP_PATH + "/img/pwa/apple-icon-180.png"} />
				)}
			</div>
			<micado-ial language={lang} path={REACT_APP_PATH}></micado-ial>
		</>
	);
};

export default Standard;

export const theme = {
	typography: {
		fontFamily: "'Marat-Sans-Regular'",
		fontSize: 22,
		fontWeightLight: 400,
		fontWeightRegular: 400,
		fontWeightMedium: 600,
		fontWeightBold: 600,
		h1: {
			fontFamily: "'Marat-Regular'",
			fontWeight: 400,
			fontSize: "clamp(42px, 8vw, 75px)",
			lineHeight: 1.2,
			letterSpacing: 0
		},
		h2: {
			fontFamily: "'Marat-Regular'",
			fontWeight: 400,
			fontSize: "clamp(35px, 7vw, 70px)",
			lineHeight: 1.2,
			letterSpacing: 0
		},
		h3: {
			fontFamily: "'Marat-Regular'",
			fontWeight: 400,
			fontSize: "clamp(32px, 6vw, 55px)",
			lineHeight: 1.2,
			letterSpacing: 0
		},
		h4: {
			fontFamily: "'Marat-Regular'",
			fontWeight: 400,
			fontSize: "clamp(28px, 5vw, 45px)",
			lineHeight: 1.2,
			letterSpacing: 0
		},
		h5: {
			fontFamily: "'Marat-Regular'",
			fontWeight: 400,
			fontSize: "clamp(25px, 5vw, 35px)",
			lineHeight: 1.2,
			letterSpacing: 0
		},
		h6: {
			fontFamily: "'Marat-Sans-Regular'",
			fontWeight: 400,
			fontSize: 20,
			lineHeight: 1.2,
			letterSpacing: 0
		},
		body1: {
			fontFamily: "'Marat-Sans-Regular'",
			fontWeight: 400,
			fontSize: 20,
			lineHeight: 1.5,
			letterSpacing: 0
		},
		body2: {
			fontFamily: "'Marat-Sans-Regular'",
			fontWeight: 400,
			fontSize: 16,
			[`@media screen and (min-width: 960px)`]: {
				fontSize: 18
			},
			lineHeight: 1.688,
			letterSpacing: 0
		},
		subtitle1: {
			fontFamily: "'Marat-Sans-Semibold'",
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 1.25,
			letterSpacing: 0
		},
		subtitle2: {
			fontFamily: "'Marat-Sans-Semibold'",
			fontWeight: 600,
			fontSize: 16,
			lineHeight: 1.125,
			letterSpacing: 0
		},
		overline: {
			fontFamily: "'Marat-Sans-Regular'",
			fontWeight: 400,
			fontSize: 18,
			[`@media screen and (min-width: 960px)`]: {
				fontSize: 22
			},
			lineHeight: 1.2,
			letterSpacing: "0.15em",
			textTransform: "uppercase"
		},
		caption: {
			fontFamily: "'Marat-Sans-Regular'",
			fontWeight: 400,
			fontSize: 14,
			lineHeight: 1.2,
			letterSpacing: "0.1em",
			textTransform: "uppercase"
		},
		button: {
			fontFamily: "'Marat-Sans-Semibold'",
			fontWeight: 600,
			fontSize: 23,
			lineHeight: 1,
			letterSpacing: "0.1em"
		}
	},
	palette: {
		brand1: "#9F9587",
		brand2: "#575654",
		primary: {
			light: "#99CCC9",
			main: "#6EB6B2",
			dark: "#448884",
			contrastText: "#FFFFFF"
		},
		secondary: {
			light: "#BCB5AB",
			main: "#9F9587",
			dark: "#575654"
		},
		tertiary: {
			light: "#E4ADAA",
			main: "#E87A74",
			dark: "#C85751"
		},
		error: {
			light: "#D76565",
			main: "#BF2222",
			dark: "#971212"
		},
		warning: {
			light: "#E3C35F",
			main: "#DAAE2B",
			dark: "#B18E25"
		},
		info: {
			light: "#94BDD1",
			main: "#4D90B2",
			dark: "#3A6E88"
		},
		success: {
			light: "#9CD3A3",
			main: "#6EB676",
			dark: "#389443"
		},
		grey: {
			50: "#FAFAFA",
			100: "#F2F2F3",
			200: "#CBCCCD",
			300: "#B1B3B4",
			400: "#97999B",
			500: "#7D8082",
			600: "#646668",
			700: "#4B4D4E",
			800: "#323334",
			900: "#191A1A",
			A100: "rgba(0, 0, 0, 0.1)",
			A200: "rgba(0, 0, 0, 0.2)",
			A500: "rgba(0, 0, 0, 0.5)",
			A800: "rgba(0, 0, 0, 0.8)"
		},
		text: {
			primary: "#575654",
			secondary: "#F5F1EB",
			disabled: "#E2D9CA"
		},
		divider: "#BCB5AB",
		background: {
			backdrop: "rgba(0, 0, 0, 0.65)",
			body: {
				light: "#FFFFFF",
				dark: "#20303D"
			},
			header: {
				light: "#FFFFFF",
				dark: "#20303D"
			},
			footer: {
				light: "#F5F1EB",
				dark: "#20303D"
			},
			menu: "#20303D",
			filter: "#FFFFFF",
			teaser: {
				dark: "#20303D",
				light: "#F5F1EB"
			},
			form: "#FFFFFF",
			default: "#FFFFFF"
		},
		gradient: {
			gradient01:
				"background: linear-gradient(180deg, rgba(159, 149, 135, 0) 0%, #9F9587 100%)",
			gradient02:
				"background: linear-gradient(180deg, rgba(32, 48, 61, 0) 0%, rgba(32, 48, 61, 0.7) 100%)"
		}
	},
	shape: {
		borderRadius: 4
	},
	overrides: {
		MuiButton: {
			root: {
				boxShadow: "none !important",
				fontSize: 18,
				height: 56,
				paddingLeft: 32,
				paddingRight: 32
			},
			sizeSmall: {
				height: 36
			},
			sizeLarge: {
				fontSize: 18,
				height: 58,
				paddingLeft: 32,
				paddingRight: 32
			}
		},
		MuiAlert: {
			root: {
				alignItems: "center"
			}
		}
	},
	zIndex: {
		filter: 1099
	},
	customTypography: {
		button: {
			small: {
				fontSize: 18,
				fontWeight: 700,
				letterSpacing: "0.4px",
				textTransform: "none"
			}
		},
		counter: {
			fontFamily: "'Marat-Sans-Semibold'",
			fontSize: 10,
			fontWeight: 600,
			lineHeight: 1
		},
		filter: {
			fontFamily: "'Marat-Sans-Semibold'",
			fontSize: 16,
			fontWeight: 600,
			lineHeight: 1.286
		},
		hero: {
			mobile: {
				hero1: {
					fontFamily: "'Marat-Regular'",
					fontWeight: 400,
					fontSize: 35,
					lineHeight: 1.1,
					letterSpacing: "0.02em",
					textAlign: "center",
					textTransform: "uppercase"
				},
				hero2: {
					fontFamily: "'Marat-Sans-Semibold'",
					fontSize: 120,
					fontWeight: 600,
					lineHeight: 1
				}
			},
			desktop: {
				hero1: {
					fontFamily: "'Marat-Regular'",
					fontWeight: 400,
					fontSize: "clamp(42px, 8vw, 75px)",
					lineHeight: 1.1,
					letterSpacing: "0.02em",
					textAlign: "center",
					textTransform: "uppercase"
				},
				hero2: {
					fontFamily: "'Marat-Sans-Semibold'",
					fontSize: 120,
					fontWeight: 600,
					lineHeight: 1
				}
			}
		},
		mainmenu: {
			mobile: {
				level1: {
					fontFamily: "'Marat-Regular'",
					fontWeight: 400,
					fontSize: 24,
					letterSpacing: "0.05em",
					lineHeight: 1.2,
					textTransform: "uppercase"
				},
				level2: {
					fontFamily: "'Marat-Sans-Regular'",
					fontWeight: 400,
					fontSize: 21,
					lineHeight: 1.25
				},
				level3: {
					fontFamily: "'Marat-Sans-Regular'",
					fontWeight: 400,
					fontSize: 16,
					lineHeight: 1.429
				},
				meta: {
					fontFamily: "'Marat-Sans-Regular'",
					fontWeight: 400,
					fontSize: 16,
					lineHeight: 1.333
				}
			},
			desktop: {
				level1: {
					fontFamily: "'Marat-Regular'",
					fontWeight: 400,
					fontSize: 80,
					letterSpacing: "0.05em",
					lineHeight: 1.111
				},
				level2: {
					fontFamily: "'Marat-Sans-Regular'",
					fontWeight: 400,
					fontSize: 30,
					lineHeight: 1.25
				},
				level3: {
					fontFamily: "'Marat-Sans-Regular'",
					fontWeight: 400,
					fontSize: 16,
					lineHeight: 1.429
				},
				meta: {
					fontFamily: "'Marat-Sans-Regular'",
					fontWeight: 400,
					fontSize: 16,
					lineHeight: 1.333
				}
			}
		},
		footer02: {
			footermenu: {
				mobile: {
					level1: {
						fontFamily: "'Marat-Sans-Regular'",
						fontWeight: 400,
						fontSize: 16,
						letterSpacing: "0.15em",
						lineHeight: 1.5,
						textTransform: "uppercase"
					},
					level2: {
						fontWeight: 400,
						fontSize: 16,
						lineHeight: 1.25,
						margin: "4px 0"
					}
				},
				desktop: {
					level1: {
						fontFamily: "'Marat-Sans-Regular'",
						fontWeight: 400,
						fontSize: 18,
						letterSpacing: "0.15em",
						lineHeight: 1.5,
						textTransform: "uppercase"
					},
					level2: {
						fontWeight: 400,
						fontSize: 18,
						lineHeight: 1.25,
						margin: "4px 0"
					}
				}
			},
			metamenu: {
				mobile: {
					level1: {
						fontWeight: 400,
						fontSize: 18,
						lineHeight: 1.3333333333333333
					}
				},
				desktop: {
					level1: {
						fontWeight: 400,
						fontSize: 18,
						lineHeight: 1.3333333333333333
					}
				}
			}
		},
		form: {
			field: {
				fontWeight: 400,
				fontSize: 18,
				lineHeight: 1.5
			},
			label: {
				fontWeight: 400,
				fontSize: 14,
				lineHeight: 1.167
			}
		}
	}
};

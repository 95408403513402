import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import PageContext from "@PageContext";
import { Element } from "react-scroll";
import WebProfileContext from "@WebProfileContext";
// import useMediaAPI from "../../../hooks/useMediaAPI";
import JobDetail from "@micado-digital/react-tourism-job/JobDetail";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";

const ReactPwaAlert = React.lazy(() =>
	import(
		/* webpackChunkName: "reactPwaAlert" */ "@micado-digital/react-pwa-alert/ReactPwaAlert"
	)
);

const JobHeader = React.lazy(() => import("@micado-digital/react-tourism-job/JobHeader"));
const JobTitleImage = React.lazy(() =>
	import("@micado-digital/react-tourism-job/JobTitleImage")
);
const JobText = React.lazy(() => import("@micado-digital/react-tourism-job/JobText"));
const JobForm = React.lazy(() => import("@micado-digital/react-tourism-job/JobForm"));
const JobAttributes = React.lazy(() =>
	import("@micado-digital/react-tourism-job/JobAttributes")
);
// const JobDetailLogo = React.lazy(() => import("./logo"));
// const ReactGallery04 = React.lazy(() =>
// 	import("@micado-digital/react-gallery/ReactGallery04")
// );
const JobDeailMap = React.lazy(() => import("./map"));
const JobDeailShare = React.lazy(() => import("./share"));

const useStyles = makeStyles(theme => ({
	jobHeader: {
		backgroundImage: "url('/img/bg.webp')",
		backgroundSize: "contain",
		backgroundRepeat: "repeat",
		position: "relative",
		"&:before": {
			background:
				"linear-gradient(180deg, rgba(32, 48, 61, 0) 0%, rgba(32, 48, 61, 0.7) 100%)",
			content: "''",
			height: "100%",
			left: 0,
			mixBlendMode: "multiply",
			position: "absolute",
			top: 0,
			width: "100%"
		},
		"& > div": {
			margin: "0 auto",
			maxWidth: 960,
			padding: theme.spacing(0, 3),
			position: "relative"
		},
		"& .mco-job-header": {
			marginBottom: theme.spacing(8),
			padding: theme.spacing(18, 0, 0),
			textAlign: "center",
			[theme.breakpoints.up("lg")]: {
				paddingTop: theme.spacing(22)
			}
		},
		"& .mco-job-title-image": {
			position: "relative",
			zIndex: 2,
			"&:before": {
				background: "#FFFFFF",
				bottom: 0,
				content: "''",
				height: "33%",
				left: -2000,
				position: "absolute",
				right: -2000,
				zIndex: -1
			}
		},
		"& .mco-job-group .MuiTypography-overline": {
			color: theme.palette.secondary.main,
			fontSize: "18px",
			letterSpacing: "0.15em",
			lineHeight: "36px"
		},
		"& .mco-job-title h1": {
			color: "#F5F1EB"
		}
	},
	jobDetail: {
		"& > div": {
			margin: "0 auto",
			maxWidth: 960,
			padding: theme.spacing(0, 3, 14)
		},
		"& .mco-job-text": {
			margin: theme.spacing(12, 0)
		},
		"& .mco-job-logo + .mco-job-text": {
			margin: theme.spacing(6, 0, 12)
		},
		"& .mco-job-text h2": {
			fontFamily: "'Marat-Regular'",
			fontSize: 35,
			lineHeight: 1,
			margin: theme.spacing(0, 0, 3),
			textAlign: "center"
		},
		"& .mco-job-text ul": {
			display: "grid",
			gap: theme.spacing(1),
			marginTop: theme.spacing(6),
			[theme.breakpoints.up("sm")]: {
				gap: theme.spacing(3, 1.5),
				gridTemplateColumns: "1fr 1fr"
			}
		},
		"& .mco-job-text ul li": {
			background: "url(/img/icons/list-job.svg)",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "left 2px",
			fontSize: 18,
			padding: theme.spacing(0, 0, 0, 4)
		},
		"& .mco-job-attributes": {
			margin: theme.spacing(12, 0)
		},
		"& .mco-job-attributes h2": {
			fontFamily: "'Marat-Regular'",
			fontSize: 35,
			lineHeight: 1,
			margin: theme.spacing(0, 0, 7),
			textAlign: "center"
		},
		"& .mco-job-attributes__item": {
			background: "#F5F1EB"
		},
		"& .mco-job-attributes svg path": {
			fill: "#9F9587"
		},
		"& .mco-job-attributes__text": {
			lineHeight: 1.4
		},
		"& .mco-job-form .MuiFormLabel-root": {
			color: "#575654"
		},
		"& .mco-job-form .mco-form-headline": {
			margin: theme.spacing(2, 0)
		},
		"& .mco-job-form .mco-form-headline p": {
			fontFamily: "'Marat-Regular'",
			fontSize: 35,
			lineHeight: 1,
			textAlign: "center"
		},
		"& .mco-job-form .mco-form-headline--sub p": {
			color: theme.palette.secondary.main,
			fontFamily: "'Marat-Sans-Regular' !important",
			fontSize: "24px !important",
			lineHeight: 1,
			textAlign: "left !important"
		},
		"& .mco-job-form .mco-form-button-wrapper": {
			padding: theme.spacing(3, 0),
			textAlign: "center"
		},
		"& .mco-job-form .mco-form-button-wrapper button": {
			background: "#9F9587"
		},
		"& .mco-job-form .MuiCheckbox-root": {
			color: "#9F9587"
		},
		"& .mco-job-gallery h2": {
			fontFamily: "'Marat-Regular'",
			fontSize: 35,
			fontWeight: 400,
			lineHeight: 1,
			margin: theme.spacing(0, 0, 5),
			textAlign: "center"
		},
		"& .mco-job-text + .mco-job-map": {
			marginTop: -40
		},
		"& .mco-job-map": {
			margin: "0 0 120px",
			[theme.breakpoints.up("sm")]: {
				display: "grid",
				gridColumnGap: theme.spacing(2),
				gridTemplateColumns: "1fr 1fr"
			},
			[theme.breakpoints.up("md")]: {
				gridColumnGap: theme.spacing(3)
			},
			"& img": {
				display: "block",
				width: "100%"
			}
		}
	}
}));

const Job = () => {
	const css = useStyles();
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const [profileData] = useContext(WebProfileContext);
	const { lang, setupDetails: data } = pageData || {};
	const [providerID, setProviderID] = useState(null);
	const sysLink = useSystemLinks();

	// const galleryData = useMediaAPI({
	// 	foldernames: "Bildergalerie - Job Detail",
	// 	lang
	// 	// locations: providerID
	// });

	useEffect(() => {
		if (data) {
			setProviderID(JSON.parse(data)?.id);
		}
	}, [data]);

	return (
		<JobDetail
			data={data ? JSON.parse(data) : null}
			lang={lang}
			pagename={window.location.pathname}
		>
			<Element name="scroll-start"></Element>
			<div className={clsx(css.jobHeader, "mco-tourism-job-detail-header")}>
				<div>
					<JobHeader />
					<JobTitleImage />
				</div>
			</div>
			<div className={clsx(css.jobDetail, "mco-tourism-job-detail")}>
				<div>
					{/* <JobDetailLogo providerID={providerID} /> */}
					<div data-anchor="Beschreibung" id="description">
						<JobText headline="Beschreibung" type="standard" />
					</div>
					<div className="mco-job-map" data-anchor="Standort" id="location">
						<img src={"/img/kaiserlodge.webp"} alt="Karriere" loading="lazy"></img>
						<JobDeailMap provideriD={providerID} />
					</div>
					{/* {galleryData && galleryData?.items?.length > 0 && (
						<div className="mco-job-gallery">
							<h2>Bildergalerie</h2>
							<ReactGallery04
								breakpoints={{
									0: {
										slidesPerView: 1,
										spaceBetween: 0
									},
									500: {
										slidesPerView: 2,
										spaceBetween: 16
									},
									980: {
										slidesPerView: 3,
										spaceBetween: 16
									}
								}}
								elements={galleryData?.items}
								lang={lang}
								mediaFormats={{
									xs: "job-detail-preview"
								}}
								showPagination={true}
								showNavigation={false}
								overlayMediaFormats={{
									xs: "job-detail-gallery"
								}}
							/>
						</div>
					)} */}
					<div data-anchor="Qualifikationen" id="qualifications">
						<JobText headline="Deine Qualifikationen" type="requirements" />
					</div>
					<div data-anchor="Aufgaben" id="tasks">
						<JobText headline="Deine Aufgaben" type="tasks" />
					</div>
					<div data-anchor="Vorteile" id="attributes">
						<JobAttributes headline="Was erwartet dich" />
					</div>
					<div data-anchor="Bewerbungsformular" id="form">
						<JobForm
							lang={lang}
							systemLinks={{
								privacy: sysLink("privacy"),
								success: sysLink("job-form-success")
							}}
						/>
					</div>
					<JobDeailShare />
				</div>
			</div>
			{profileData?.pwaShowInstallHint && (
				<ReactPwaAlert appIcon={REACT_APP_PATH + "/img/pwa/apple-icon-180.png"} />
			)}
			<micado-ial language={lang} path={REACT_APP_PATH}></micado-ial>
		</JobDetail>
	);
};

export default Job;

import React from "react";
import clsx from "clsx";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useScrollYPosition } from "react-use-scroll-position";
import { Link } from "react-scroll";

import styles from "./AnchorMenu.styles";

const AnchorMenu = ({ items }) => {
	const theme = useTheme();
	const screenLG = useMediaQuery(theme.breakpoints.up("lg"));
	const scrollY = useScrollYPosition();
	const headerScroll = scrollY > 100;
	const css = styles();

	return (
		<div
			className={clsx(css.root, "mco-view-component-anchormenu", {
				[css.showAnchorMenu]: headerScroll
			})}
		>
			<div className={clsx(css.container, "mco-view-component-anchormenu__container")}>
				<div className={clsx(css.scroller, "mco-view-component-anchormenu__scroller")}>
					<div className={clsx(css.wrapper, "mco-view-component-anchormenu__wrapper")}>
						{items?.map((item, index) => {
							return (
								<Link
									className={clsx(css.item, "mco-view-component-anchormenu__item")}
									activeClass="active"
									key={index}
									to={item.tag}
									offset={screenLG ? -180 : -160}
									spy={true}
									smooth={true}
									duration={500}
								>
									{item.title}
								</Link>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AnchorMenu;

import React, { useContext, Suspense, lazy } from "react";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import Container from "../../../helper/container";
import WebProfileContext from "@WebProfileContext";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "../../../locales/de.json";
import localEN from "../../../locales/en.json";

const CustomButton = lazy(() =>
	import(/* webpackChunkName: "reactDropDown" */ "../../../components/button")
);
const DropDown = lazy(() =>
	import(/* webpackChunkName: "reactDropDown" */ "../../../components/dropdown")
);
const Spacing = lazy(() =>
	import(/* webpackChunkName: "reactSpacing" */ "../../../components/spacing")
);
const Singlteaser = lazy(() =>
	import(/* webpackChunkName: "reactSinglteaser" */ "../../../components/singleteaser")
);
const HeadlineTextCombi = lazy(() =>
	import(/* webpackChunkName: "reactHeadlineTextCombi" */ "../../../components/headline-text")
);
const TeaserSlider = lazy(() =>
	import(/* webpackChunkName: "reactTeaserSlider" */ "../../../components/teaser-slider")
);
const ReactSVGIcon = lazy(() =>
	import(/* webpackChunkName: "reactSVGIcon" */ "@micado-digital/react-svg-icon/ReactSVGIcon")
);
const ReactHeadline01 = lazy(() =>
	import(
		/* webpackChunkName: "reactHeadline01" */ "@micado-digital/react-headline/ReactHeadline01"
	)
);
const ReactFile01 = lazy(() =>
	import(/* webpackChunkName: "reactFile01" */ "@micado-digital/react-file/ReactFile01")
);
const ReactGallery04 = lazy(() =>
	import(
		/* webpackChunkName: "reactGallery04" */ "@micado-digital/react-gallery/ReactGallery04"
	)
);
const ReactGallery03 = lazy(() =>
	import(
		/* webpackChunkName: "reactGallery03" */ "@micado-digital/react-gallery/ReactGallery03"
	)
);
const ReactList01 = lazy(() =>
	import(/* webpackChunkName: "reactList01" */ "@micado-digital/react-list/ReactList01")
);
const ReactText01 = lazy(() =>
	import(/* webpackChunkName: "reactText01" */ "@micado-digital/react-text/ReactText01")
);
const ReactImageText01 = lazy(() =>
	import(
		/* webpackChunkName: "reactImageText01" */ "@micado-digital/react-imagetext/ReactImageText01"
	)
);
const ReactReferences01 = lazy(() =>
	import(
		/* webpackChunkName: "reactReferences01" */ "@micado-digital/react-references/ReactReferences01"
	)
);
const ReactReferences02 = lazy(() =>
	import(
		/* webpackChunkName: "reactReferences02" */ "@micado-digital/react-references/ReactReferences02"
	)
);
const ReactSingleImage01 = lazy(() =>
	import(
		/* webpackChunkName: "singleImage01" */ "@micado-digital/react-singleimage/ReactSingleImage01"
	)
);
const ReactTable01 = lazy(() =>
	import(/* webpackChunkName: "reactTable01" */ "@micado-digital/react-table/ReactTable01")
);
const ReactTeaser01 = lazy(() =>
	import(/* webpackChunkName: "reactTeaser01" */ "@micado-digital/react-teaser/ReactTeaser01")
);
const ReactTeaserList01 = lazy(() =>
	import(
		/* webpackChunkName: "reactTeaserList01" */ "@micado-digital/react-teaser-list/ReactTeaserList01"
	)
);
const ReactVideo02 = lazy(() =>
	import(/* webpackChunkName: "reactVideo02" */ "@micado-digital/react-video/ReactVideo02")
);
const ReactCopyrights01 = lazy(() =>
	import(
		/* webpackChunkName: "reactCopyrights01" */ "@micado-digital/react-copyrights/ReactCopyrights01"
	)
);
const ReactHTML = lazy(() =>
	import(/* webpackChunkName: "reactHTML" */ "@micado-digital/react-html/ReactHTML")
);
const Typography = lazy(() =>
	import(/* webpackChunkName: "materialUITypography" */ "@material-ui/core/Typography")
);
const TourismJobOffersList = lazy(() =>
	import("@micado-digital/react-tourism-job/views/List01")
);

const Form = lazy(() => import(/* webpackChunkName: "form" */ "@micado-digital/react-form"));
const Connector = lazy(() =>
	import(/* webpackChunkName: "connector" */ "@micado-digital/react-form/Connector")
);
const Team = lazy(() =>
	import(/* webpackChunkName: "reactTeam" */ "../../../components/team")
);
const Benefits = lazy(() =>
	import(/* webpackChunkName: "reactBenefits" */ "../../../components/benefits")
);

const Elements = ({ items, lang, layoutVariant }) => {
	const [profileData] = useContext(WebProfileContext);
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	if (!items) return <></>;

	let isInDropdown = false;
	let isDropdownStartTag = false;
	let isDropdownEndTag = false;
	let currentDropdown = null;

	let newElements = cloneDeep(items);

	newElements = newElements.filter(item => {
		if (item.tag === "basic-dropdown") {
			if (item.option === 1) {
				currentDropdown = null;
				isDropdownStartTag = false;
				isDropdownEndTag = true;
				isInDropdown = false;
			} else {
				currentDropdown = item;
				isDropdownStartTag = true;
				isDropdownEndTag = false;
				isInDropdown = true;
			}
		} else {
			isDropdownStartTag = false;
			isDropdownEndTag = false;
		}

		if (isInDropdown && !isDropdownStartTag && currentDropdown) {
			if (currentDropdown._subItems) {
				currentDropdown._subItems.push(item);
			} else {
				currentDropdown._subItems = [item];
			}
		} else if (!isDropdownEndTag) {
			return item;
		}

		return null;
	});

	return (
		<Suspense fallback={<></>}>
			{newElements.map(item => {
				return (
					<React.Fragment key={item.id}>
						{parseElements(item, lang, layoutVariant, profileData, l)}
					</React.Fragment>
				);
			})}
		</Suspense>
	);
};

const parseElements = (item, lang, layoutVariant, profileData, l) => {
	const { REACT_APP_PATH } = process.env;

	switch (item?.tag) {
		case "basic-headline":
			return (
				<Container
					key={item?.id}
					maxWidth="md"
					name="headline"
					variant={(item?.variant || 0) + 1}
					anchor={item?.anchor?.tag}
				>
					{item.variant === 6 ? (
						<Typography
							className="mco-headline"
							dangerouslySetInnerHTML={{ __html: item?.text }}
							variant="overline"
						/>
					) : (
						<Suspense fallback={<></>}>
							<ReactHeadline01 text={item?.text} variant={item.variant} />
						</Suspense>
					)}
				</Container>
			);

		case "basic-spacing": {
			return (
				<Container key={item?.id} maxWidth="md" name="spacing" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<Spacing />
					</Suspense>
				</Container>
			);
		}

		case "basic-text": {
			return (
				<Container
					key={item?.id}
					maxWidth="md"
					name="text"
					variant={item?.variant}
					anchor={item?.anchor?.tag}
				>
					<Suspense fallback={<></>}>
						<ReactText01 text={item?.text} />
					</Suspense>
				</Container>
			);
		}

		case "basic-list": {
			return (
				<Container key={item?.id} maxWidth="md" name="list" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<ReactList01
							headlineVariant="h5"
							icon={
								<ReactSVGIcon color="secondary" src="/img/icons/list-icon.svg" size={24} />
							}
							spacing={2}
							textcontent={item?.textcontent}
							title={item?.title}
							variant={item?.variant}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-links": {
			return (
				<Container key={item?.id} maxWidth="md" name="links" anchor={item?.anchor?.tag}>
					{item.variant === 1 ? (
						<Suspense fallback={<></>}>
							<ReactReferences02
								buttonSize="small"
								buttonVariant="contained"
								elements={item?.elements}
								headlineVariant="h5"
								title={item?.title}
							/>
						</Suspense>
					) : (
						<Suspense fallback={<></>}>
							<ReactReferences01
								elements={item?.elements}
								title={item?.title}
								endIcon={
									<ReactSVGIcon color="primary" src="/img/icons/arrow-link.svg" size={24} />
								}
								hasIcon={false}
								headlineVariant="h5"
							/>
						</Suspense>
					)}
				</Container>
			);
		}

		case "basic-gallery": {
			let breakpoints = {
				0: {
					slidesPerView: 1,
					spaceBetween: 32
				}
			};

			return item?.variant === 1 ? (
				<Container key={item?.id} maxWidth="xl" name="gallery" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<ReactGallery04
							delay={5000}
							elements={item?.elements}
							navNext="/img/icons/arrow-next.svg"
							navPrev="/img/icons/arrow-prev.svg"
							mediaFormats={{
								xs: "basic-gallery-mobile",
								sm: "basic-gallery-slider"
							}}
							overlayMediaFormats={{
								xs: "basic-gallery"
							}}
							speed={1000}
							showPagination={true}
							showNavigation={true}
							breakpoints={breakpoints}
						/>
					</Suspense>
				</Container>
			) : (
				<Container key={item?.id} maxWidth="lg" name="gallery" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<ReactGallery03
							breakpoints={{
								xs: 1,
								sm: 2,
								md: 3
							}}
							elements={item?.elements}
							lang={lang}
							mediaFormats={{
								xs: "basic-gallery-mobile",
								sm: "basic-gallery-thumb"
							}}
							overlayMediaFormats={{
								xs: "basic-gallery"
							}}
							spacing={24}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-download": {
			return (
				<Container key={item?.id} maxWidth="lg" name="download" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<ReactFile01 media={item?.media} title={item?.title} />
					</Suspense>
				</Container>
			);
		}

		case "basic-imagetext": {
			return (
				<Container
					key={item?.id}
					name="imagetext"
					maxWidth="lg"
					variant={item?.variant}
					anchor={item?.anchor?.tag}
				>
					<Suspense fallback={<></>}>
						<ReactImageText01
							addition2={item?.addition2}
							alt={item?.addition}
							option={item?.option}
							media={item?.media ? item?.media?.[0] : {}}
							mediaFormats={{
								xs: "basic-imagetext-mobile",
								sm: "basic-imagetext"
							}}
							mediaPath={REACT_APP_PATH}
							text={item?.text}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-singleimage": {
			let mediaFormats = null;

			if (layoutVariant === "blog") {
				mediaFormats = {
					xs: "basic-singleimage-blog"
				};
			} else {
				mediaFormats = {
					xs: "basic-singleimage-mobile",
					sm: "basic-singleimage"
				};
			}

			return (
				<Container
					key={item?.id}
					maxWidth={layoutVariant === "blog" ? "md" : "xl"}
					name="singleimage"
					anchor={item?.anchor?.tag}
				>
					<Suspense fallback={<></>}>
						<ReactSingleImage01
							addition={item?.addition}
							option2={item?.option2}
							media={item?.media ? item?.media?.[0] : {}}
							mediaFormats={mediaFormats}
							mediaPath={REACT_APP_PATH}
							reference={item?.reference}
							title={item?.title}
							text={item?.text}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-form": {
			return (
				<Container key={item?.id} maxWidth="lg" name="form" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<Form
							action={`${REACT_APP_PATH}/submitform.json.api`}
							method="POST"
							key={item?.id}
						>
							<Connector formElements={item?.elements} formID={item?.id} />
						</Form>
					</Suspense>
				</Container>
			);
		}

		case "basic-singleteaser": {
			return (
				<Container key={item?.id} maxWidth="lg" name="singleteaser" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<Singlteaser
							title={item?.teaser?.title}
							text={item?.teaser?.text}
							buttonLabel={item?.teaser?.addition}
							link={item?.teaser?.link}
							media={item?.teaser?.media ? item?.teaser?.media?.[0] : {}}
							mediaPath={REACT_APP_PATH}
							variant={item?.variant}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-external-singleteaser": {
			let title = item?.textcontent?.items.find(e => e.name === "Title")?.text;
			let text = item?.textcontent?.items.find(e => e.name === "Text")?.text;
			let link = item?.textcontent?.items.find(e => e.name === "URL")?.text;
			let media = item?.elements?.find(e => e.media);

			return (
				<Container
					key={item?.id}
					maxWidth="lg"
					name="external-singleteaser"
					anchor={item?.anchor?.tag}
				>
					<Suspense fallback={<></>}>
						<Singlteaser
							title={title}
							text={text}
							buttonLabel={l("singleteaser.button")}
							link={link}
							media={media?.media ? media?.media?.[0] : {}}
							mediaPath={REACT_APP_PATH}
							target="_blank"
							variant={item?.variant}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-teaser": {
			const marks = item?.teasergroup?.items?.filter(item => {
				return item?.marks;
			});

			if (item?.variant === 1) {
				return (
					<Container
						maxWidth="xl"
						name={
							marks && marks.length > 0
								? "teaser-list-custom-with-marks"
								: "teaser-list-custom"
						}
						anchor={item?.anchor?.tag}
					>
						<Suspense fallback={<></>}>
							<TeaserSlider
								counter={true}
								items={item?.teasergroup?.items}
								hasMarks={marks && marks.length > 0 ? true : false}
								lang={lang}
							/>
						</Suspense>
					</Container>
				);
			} else if (item?.variant === 2) {
				return (
					<Container
						maxWidth="xl"
						name={
							marks && marks.length > 0
								? "teaser-list-custom-with-marks"
								: "teaser-list-custom"
						}
						anchor={item?.anchor?.tag}
					>
						<Suspense fallback={<></>}>
							<TeaserSlider
								counter={false}
								items={item?.teasergroup?.items}
								hasMarks={marks && marks.length > 0 ? true : false}
							/>
						</Suspense>
					</Container>
				);
			} else {
				let breakpoints = {
					0: {
						slidesPerView: 1.1,
						spaceBetween: 16
					},
					600: {
						slidesPerView: 2,
						spaceBetween: 24
					},
					900: {
						slidesPerView: 3,
						spaceBetween: 24
					}
				};

				return (
					<Container maxWidth="lg" name="teaser-list" anchor={item?.anchor?.tag}>
						<Suspense fallback={<></>}>
							<ReactTeaserList01
								autoplay={false}
								delay={5000}
								headline={item?.title}
								loop={false}
								showPagination={true}
								showNavigation={false}
								sliderBreakpoints={breakpoints}
								speed={1000}
								variant="slider"
							>
								{item?.teasergroup?.items?.map(
									({ id, link, media, title, text, linkTarget, from, to }) => {
										return (
											<ReactTeaser01
												button={l("ReactTeaser01.button")}
												buttonSize="small"
												buttonVariant="text"
												key={id}
												link={link}
												linkElement={true}
												media={media ? media?.[0] : {}}
												mediaFormats={{
													xs: "basic-teaser"
												}}
												mediaPath={REACT_APP_PATH}
												target={linkTarget}
												text={text ? text : ""}
												title={title ? title : ""}
											/>
										);
									}
								)}
							</ReactTeaserList01>
						</Suspense>
					</Container>
				);
			}
		}

		case "basic-html":
			return (
				<Container key={item?.id} maxWidth="lg" name="html" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<ReactHTML html={item?.text} />
					</Suspense>
				</Container>
			);

		case "basic-video": {
			return (
				<Container key={item?.id} maxWidth="lg" name="video" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<ReactVideo02
							media={item?.media[0]}
							mediaFormats={{ xs: "video-mobile", sm: "video" }}
							mediaPath={REACT_APP_PATH}
							title={item?.title}
							text={item?.text}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-table": {
			const newTableArray = [];

			for (const entries of item?.textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});
				newTableArray.push(columns);
			}

			return (
				<Container
					key={item?.id}
					maxWidth={item?.variant === 1 ? "lg" : "md"}
					name="table"
					variant={layoutVariant}
					anchor={item?.anchor?.tag}
				>
					<Suspense fallback={<></>}>
						<ReactTable01
							addition={item?.addition}
							headlineVariant="h5"
							items={newTableArray}
							title={item?.title}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-dropdown": {
			return (
				<Container key={item?.id} maxWidth="lg" name="dropdown" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<DropDown data={item}>{map(item?._subItems, parseElements)}</DropDown>
					</Suspense>
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container key={item?.id} maxWidth="md" name="copyrights" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<ReactCopyrights01 />
					</Suspense>
				</Container>
			);
		}

		case "extension-headline-text": {
			let headline = null;
			let text = null;

			item?.textcontent?.items?.filter(item => {
				if (item?.name === "headline") {
					headline = item?.text;
				}
				if (item?.name === "text") {
					text = item?.text;
				}
				return null;
			});

			return (
				<Container
					key={item?.id}
					maxWidth="lg"
					name="headline-text"
					anchor={item?.anchor?.tag}
				>
					<Suspense fallback={<></>}>
						<HeadlineTextCombi headline={headline} text={text}></HeadlineTextCombi>
					</Suspense>
				</Container>
			);
		}

		case "extension-button": {
			const label = item?.textcontent?.items?.find(item => item?.name === "valLabel")?.text;
			const link = item?.elements?.find(item => item?.tag === "valLink")?.reference?.pageName;
			const externalLink = item?.textcontent?.items?.find(
				item => item?.name === "valExternalLink"
			)?.text;
			const target = item?.textcontent?.items?.find(item => item?.name === "valTarget")?.text;

			return (
				<Container
					key={item?.id}
					maxWidth="lg"
					name="extension-button"
					anchor={item?.anchor?.tag}
				>
					<CustomButton
						label={label}
						link={link ? link : externalLink}
						target={externalLink && target === "True" ? "_blank" : "_top"}
					/>
				</Container>
			);
		}

		case "tourism-job-list": {
			const { textcontent: { items } = {} } = item;

			const categoryID = items?.find(item => item.name === "valCategory")?.text;
			const headline = items?.find(item => item.name === "valHeadline")?.text;

			return (
				<Container
					className={headline ? "has-headline" : ""}
					key={item?.id}
					name="job-list"
					anchor={item?.anchor?.tag}
				>
					<Container maxWidth="lg">
						<Suspense fallback={<></>}>
							<>
								{headline && (
									<Typography
										className="job-list__headline"
										variant="h3"
										dangerouslySetInnerHTML={{ __html: headline }}
									/>
								)}
								<TourismJobOffersList categories={categoryID} group />
							</>
						</Suspense>
					</Container>
				</Container>
			);
		}

		case "tourism-team": {
			const headline = item?.textcontent?.items?.find(
				item => item?.name === "valHeadline"
			)?.text;
			const group = item?.textcontent?.items?.find(item => item?.name === "valGroup")?.text;

			return (
				<Container key={item?.id} maxWidth="lg" name="team" anchor={item?.anchor?.tag}>
					<Suspense fallback={<></>}>
						<>
							<Team group={group} headline={headline} lang={lang} />
						</>
					</Suspense>
				</Container>
			);
		}

		case "extension-benefits": {
			const headline = item?.textcontent?.items?.find(
				item => item?.name === "valHeadline"
			)?.text;
			const items = item?.elements?.filter(item => item?.tag === "extension-benefits-select");

			return (
				<Container key={item?.id} name="benefits" anchor={item?.anchor?.tag}>
					<Container maxWidth="md">
						<Suspense fallback={<></>}>
							<>
								<Benefits data={items} headline={headline} />
							</>
						</Suspense>
					</Container>
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.log("missing element:", item);
			}

			return null;
	}
};

export default Elements;

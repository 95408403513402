import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {
		background: "#F5F1EB",
		boxShadow: "0px 0px 20px rgba(78, 50, 28, 0.2)",
		display: "flex",
		justifyContent: "center",
		opacity: 0,
		position: "fixed",
		pointerEvents: "none",
		top: 80,
		transition: theme.transitions.create(["opacity", "position"], {
			duration: theme.transitions.duration.short
		}),
		width: "100%",
		zIndex: theme.zIndex.appBar - 1,
		[theme.breakpoints.up("lg")]: {
			top: 100
		}
	},
	showAnchorMenu: {
		opacity: 1,
		pointerEvents: "all"
	},
	container: {
		display: "flex",
		overflow: "hidden",
		margin: 0 + " !important",
		minHeight: 50,
		padding: theme.spacing(0, 1),
		WebkitOverflowScrolling: "touch"
	},
	scroller: {
		display: "inline-block",
		flex: "1 1 auto",
		marginBottom: 0,
		overflowX: "scroll",
		position: "relative",
		scrollbarWidth: "none",
		whiteSpace: "nowrap",
		"&::-webkit-scrollbar": {
			display: "none"
		}
	},
	wrapper: {
		alignItems: "center",
		display: "flex",
		height: "100%",
		gap: theme.spacing(1)
	},
	item: {
		color: "#575654",
		cursor: "pointer",
		fontSize: 18,
		fontWeight: 400,
		padding: theme.spacing(1, 1.5),
		transition: theme.transitions.create(["color"], {
			duration: theme.transitions.duration.short
		}),
		"&.active": {
			color: theme.palette.primary.main
		},
		"&:hover": {
			color: theme.palette.primary.main
		}
	}
}));

export default styles;

import React, { useContext, useEffect, useState, Suspense, lazy } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import makeStyles from "@material-ui/styles/makeStyles";
import PageContext from "@PageContext";
import LayoutStandard from "../views/template/standard";
import LayoutJob from "../views/template/job";
import Error404 from "../views/template/errors/404";
import Header from "../views/components/header";
import AnchorMenu from "../views/components/anchorMenu";

const Animation = lazy(() =>
	import(/* webpackChunkName: "animation" */ "../views/components/animation")
);
const Footer = lazy(() =>
	import(/* webpackChunkName: "footer" */ "../views/components/footer")
);

const useStyles = makeStyles(theme => ({
	page: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		paddingTop: "calc(env(safe-area-inset-top))",
		overflow: "hidden",
		[theme.breakpoints.up("sm")]: {
			paddingTop: "calc(env(safe-area-inset-top))"
		}
	}
}));

const DetectLayout = () => {
	const [pageData] = useContext(PageContext);
	const { animationID, lang, layoutTag, layoutVariant, menuGroups, state } = pageData || {};
	const [day, setDay] = useState(false);
	const [anchors, setAnchors] = useState([]);
	const css = useStyles();

	useEffect(() => {
		if (layoutVariant === "no-animation") {
			document?.body?.classList?.add("no-animation");
		} else {
			document?.body?.classList?.remove("no-animation");
		}
	}, [layoutVariant]);

	const jobDetail = document?.querySelector(".mco-tourism-job-detail");

	useEffect(() => {
		if (jobDetail) {
			setTimeout(() => {
				const anchorDivs = jobDetail?.querySelectorAll("div[data-anchor]");
				let anchors = [];

				anchorDivs?.forEach(item => {
					const tag = item?.id;
					const title = item?.getAttribute("data-anchor");

					anchors?.push({ tag: tag, title: title });
				});

				setAnchors(anchors);
			}, 1000);
		}
	}, [jobDetail]);

	// get day mode

	const hours = new Date().getHours();

	useEffect(() => {
		setDay(hours >= 6 && hours < 18);
	}, [hours]);

	if (isEmpty(pageData)) {
		return <></>;
	}

	const mainmenu = menuGroups?.find(item => item.title === "Hauptmenü");
	const metamenu = menuGroups?.find(item => item.title === "Metamenü");

	switch (layoutTag) {
		case "standard":
			return (
				<div className={clsx(css.page, "mco-page")}>
					<Header
						lang={lang}
						mainmenu={mainmenu}
						metamenu={metamenu}
						mode={day}
						fixed={layoutVariant === "no-animation"}
						variant={layoutVariant}
					/>
					{layoutVariant !== "no-animation" && (
						<Suspense fallback={<></>}>
							<Animation animationID={animationID} lang={lang} layoutVariant={layoutVariant} />
						</Suspense>
					)}
					<LayoutStandard variant={layoutVariant} />
					<Suspense fallback={<></>}>
						<Footer lang={lang} />
					</Suspense>
				</div>
			);
		case "tourism-job-detail":
			return (
				<div className={clsx(css.page, "mco-page")}>
					<Header
						lang={lang}
						mainmenu={mainmenu}
						metamenu={metamenu}
						mode={day}
						fixed={false}
						variant={layoutVariant}
					/>
					{anchors?.length > 0 && <AnchorMenu items={anchors} />}
					<LayoutJob />
					<Suspense fallback={<></>}>
						<Footer lang={lang} />
					</Suspense>
				</div>
			);
		default: {
			// errors

			// eslint-disable-next-line default-case
			switch (state) {
				case 404:
				default:
					return (
						<div className={clsx(css.page, "mco-page")}>
							<Header lang={lang} mainmenu={mainmenu} />
							<Suspense fallback={<></>}>
								<Error404 />
								<Footer />
							</Suspense>
						</div>
					);
			}
		}
	}
};

export default DetectLayout;
